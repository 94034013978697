import { ReactNode, useRef } from "react";
import Slider from "react-slick";

interface CsSliderInnerProps {
  /**
   * Slider settings from React slick
   * See: https://react-slick.neostack.com/
   */
  settings: any;
  children: ReactNode;
  controlsPosition?: "side" | "bottom";
}

export const CSliderInner = ({
  settings,
  children,
  controlsPosition,
}: CsSliderInnerProps) => {
  const slider = useRef(null);
  let controlsClass = "controls";
  if (controlsPosition === "bottom") {
    controlsClass += " controls-testimonial";
  }

  return (
    <div className="position-relative">
      <ul className={controlsClass}>
        <li className="prev" onClick={() => slider.current.slickPrev()}>
          <i className="fe fe-chevron-left"></i>
        </li>
        <li className="next" onClick={() => slider.current.slickNext()}>
          <i className="fe fe-chevron-right"></i>
        </li>
      </ul>
      <Slider ref={slider} {...settings} className="testimonials">
        {children}
      </Slider>
    </div>
  );
};

export default CSliderInner;
